// Core
import cx from "classnames";

// Components
import { Row, Col, Grid } from "antd";
import { Title } from "components/ui/Typography/Title";
import { Text } from "components/ui/Typography/Text";
import { Container } from "components/common/Container";
import { RetailerBrands } from "components/common/RetailerBrands";
import { Image } from "components/ui/Image";

// Utils
import { rowGutters } from "utils/constants";
import { introRetailer } from "data/intro";
import { useTranslation } from "client/utils/i18n/client";

import st from "./styles.module.css";

export const IntroRetailer = () => {
  const { t } = useTranslation();
  const { md = true, lg = true } = Grid.useBreakpoint();
  const isTablet = !lg;
  const isMobile = !md;
  const isDesktop = !isTablet && !isMobile;

  const retailerStyles = cx(st.retailer, {
    [st.mobile]: isMobile,
    [st.tablet]: isTablet,
  });
  const retailerImageStyles = cx(st.image, {
    [st.mobile]: isMobile,
  });

  const headJSX = t(introRetailer.head);
  const titleJSX = t(introRetailer.title);
  const descriptionJSX = t(introRetailer.desc);

  return (
    <section className={retailerStyles} data-test-id="intro-retailer">
      <Container>
        <Row gutter={rowGutters} justify="space-between">
          {isDesktop && (
            <Col xl={16} lg={16} md={15} xs={24}>
              <div className={st.content}>
                <Title
                  tag="h2"
                  size="36"
                  font="second"
                  fontWeight="700"
                  color={"white-100"}
                  className={st.head}
                >
                  {headJSX}
                </Title>
                <Title
                  tag="h3"
                  size="36"
                  font="second"
                  fontWeight="700"
                  color={"orange-10"}
                  className={st.title}
                >
                  {titleJSX}
                </Title>
                <Text box="full" color="white-100">
                  {descriptionJSX}
                </Text>
                <div className={st["retailer-brands-content"]}>
                  <RetailerBrands items={introRetailer.brands} />
                </div>
              </div>
            </Col>
          )}
          {isTablet && !isMobile && (
            <Col xl={16} lg={16} md={15} xs={24}>
              <div className={st["content-tablet"]}>
                <Title
                  tag="h2"
                  size="30"
                  font="second"
                  fontWeight="700"
                  color={"white-100"}
                  className={st.head}
                >
                  {headJSX}
                </Title>
                <Title
                  tag="h3"
                  size="30"
                  font="second"
                  fontWeight="700"
                  color={"orange-10"}
                  className={st.title}
                >
                  {titleJSX}
                </Title>
                <Text box="full" color="white-100">
                  {descriptionJSX}
                </Text>
                <div className={st["retailer-brands-content-tablet"]}>
                  <RetailerBrands items={introRetailer.brands} />
                </div>
              </div>
            </Col>
          )}
          {isMobile && (
            <Col xl={16} lg={16} md={15} xs={24}>
              <div className={st["content-mobile"]}>
                <Title
                  tag="h2"
                  size="18"
                  font="second"
                  fontWeight="700"
                  color={"white-100"}
                  className={st["head-mobile"]}
                >
                  {headJSX}
                </Title>
                <Title
                  tag="h3"
                  size="18"
                  font="second"
                  fontWeight="700"
                  color={"orange-10"}
                  className={st["title-mobile"]}
                >
                  {titleJSX}
                </Title>
                <Text box="full" size="14" color="white-100">
                  {descriptionJSX}
                </Text>
                <div className={st["retailer-brands-content-mobile"]}>
                  <RetailerBrands items={introRetailer.brands} />
                </div>
              </div>
            </Col>
          )}
          <Col xl={8} lg={8} md={9} xs={24}>
            <div className={retailerImageStyles}>
              <Image
                src={introRetailer.img.src}
                alt={t(introRetailer.img.alt)}
                layout="fill"
                objectFit="cover"
                objectPosition="center center"
                placeholder="blur"
                priority
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
