// Core
import cx from "classnames";

// Components
import { Grid, Row, Col } from "antd";
import { Image } from "components/ui/Image";
import { Icon } from "components/ui/Icon";
import { Title } from "components/ui/Typography/Title";
import { Text } from "components/ui/Typography/Text";

// Utils
import { freedom } from "data/intro";
import { useTranslation } from "client/utils/i18n/client";

import st from "./styles.module.css";

export const IntroFreedom = () => {
  const { t } = useTranslation();
  const { md = true, lg = true } = Grid.useBreakpoint();
  const isTablet = !lg;
  const isMobile = !md;

  const stringsStyles = cx(st["freedom-overview-cell"], st["cell-strings"], {
    [st["freedom-overview-cell-wide"]]: isMobile || (!isTablet && !isMobile),
    [st["freedom-overview-cell-full"]]: isTablet && !isMobile,
  });
  const cellTitleStyles = cx(st["freedom-overview-cell"], st["cell-title"], {
    [st["freedom-overview-cell-wide"]]: !isTablet && !isMobile,
  });
  const cellOrderStyles = cx(st["freedom-overview-cell"], st["cell-order"], {
    [st["freedom-overview-cell-wide"]]: !isMobile,
  });
  const promoImgStyle = cx(st["freedom-overview-cell"]);
  const cellProStyles = cx(st["freedom-overview-cell"], st["cell-pro"], {
    [st.mobile]: isMobile,
  });
  const cellLeafStyles = cx(st["freedom-overview-cell"], st["cell-leaf"], {
    [st.mobile]: isMobile,
  });

  const stringsJSX = (
    <div className={stringsStyles}>
      <Text
        box="full"
        size="24"
        color="white-100"
        font="second"
        fontWeight="700"
        alignmentHorizontal="center"
      >
        {t(freedom.strings)}
      </Text>
    </div>
  );
  const orderJSX = (
    <div className={cellOrderStyles}>
      <Text
        box="full"
        size="24"
        color="white-100"
        font="second"
        fontWeight="700"
        alignmentHorizontal="center"
      >
        {t(freedom.order)}
      </Text>
    </div>
  );
  const titleJSX = (
    <div className={cellTitleStyles}>
      <Title
        tag="h2"
        size="36"
        color="white-100"
        font="second"
        fontWeight="700"
        alignmentHorizontal="center"
      >
        {t(freedom.title)}
      </Title>
    </div>
  );
  const freedomImgJSX = (
    <div className={promoImgStyle}>
      <Image
        className={st["image-promo-img"]}
        src={freedom.img1.src}
        alt={t(freedom.img1.alt)}
        placeholder="blur"
        layout="fill"
        objectFit="cover"
      />
    </div>
  );
  const proJSX = (
    <div className={cellProStyles}>
      <Icon name="ProCross" size="fullWidth" />
    </div>
  );
  const leafJSX = (
    <div className={cellLeafStyles}>
      <Icon name="Leaf" size="fullWidth" />
    </div>
  );

  return (
    <section className={st.freedom}>
      {!isTablet && !isMobile && (
        <Row className={st["freedom-overview"]}>
          <Col span={16}>
            <Row>
              <Col span={12}>{orderJSX}</Col>
              <Col span={12}>{titleJSX}</Col>
            </Row>
            <Row>
              <Col span={6}>{leafJSX}</Col>
              <Col span={12}>{stringsJSX}</Col>
              <Col span={6}>{proJSX}</Col>
            </Row>
          </Col>
          <Col span={8}>{freedomImgJSX}</Col>
        </Row>
      )}
      {isTablet && !isMobile && (
        <Row className={st["freedom-overview"]}>
          <Col span={24}>
            <Row>
              <Col span={15}>{orderJSX}</Col>
              <Col span={9}>
                <div className={cellTitleStyles}>
                  <Title
                    tag="h2"
                    size="30"
                    color="white-100"
                    font="second"
                    fontWeight="700"
                    alignmentHorizontal="center"
                  >
                    {t(freedom.title)}
                  </Title>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={9}>
                <Row>
                  <Col span={24}>{leafJSX}</Col>
                  <Col span={24}>{proJSX}</Col>
                </Row>
              </Col>
              <Col span={15}>{freedomImgJSX}</Col>
            </Row>
            <Row>
              <Col span={24}>{stringsJSX}</Col>
            </Row>
          </Col>
        </Row>
      )}
      {isMobile && (
        <Row className={st["freedom-overview"]}>
          <Col span={24}>
            <Row>
              <Col span={12}>
                <div className={cellOrderStyles}>
                  <Text
                    box="full"
                    size="16"
                    color="white-100"
                    font="second"
                    fontWeight="700"
                    alignmentHorizontal="center"
                  >
                    {t(freedom.order)}
                  </Text>
                </div>
              </Col>
              <Col span={12}>
                <div className={cellTitleStyles}>
                  <Title
                    tag="h2"
                    size="18"
                    color="white-100"
                    font="second"
                    fontWeight="700"
                    alignmentHorizontal="center"
                  >
                    {t(freedom.title)}
                  </Title>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>{freedomImgJSX}</Col>
            </Row>
            <Row>
              <Col span={12}>{leafJSX}</Col>
              <Col span={12}>{proJSX}</Col>
            </Row>
            <Row>
              <Col span={24}>
                <div className={stringsStyles}>
                  <Text
                    box="full"
                    size="16"
                    color="white-100"
                    font="second"
                    fontWeight="700"
                    alignmentHorizontal="center"
                  >
                    {t(freedom.strings)}
                  </Text>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </section>
  );
};
