// Definitions
import { UserCountryCodesEnum } from "bus/user/models";
import type { YoutubePlayerOptionsType } from "components/ui/YoutubePlayer";

const RETAILER_FR_VIDEO_ID = "vJzf6ovZHkY";
const RETAILER_EN_VIDEO_ID = "O-nyEgy4_r8";
const RETAILER_DE_VIDEO_ID = "anc6qN2KOvs";
const RETAILER_NL_VIDEO_ID = "y8JOO9iPSdQ";

export const getVideoId = (language?: string) => {
  if (language === UserCountryCodesEnum.fr) {
    return RETAILER_FR_VIDEO_ID;
  }
  if (language === UserCountryCodesEnum.de) {
    return RETAILER_DE_VIDEO_ID;
  }
  if (language === UserCountryCodesEnum.at) {
    return RETAILER_DE_VIDEO_ID;
  }
  if (language === UserCountryCodesEnum.nl) {
    return RETAILER_NL_VIDEO_ID;
  }

  return RETAILER_EN_VIDEO_ID;
};

export const getRetailerVideoOps = ({
  language,
  blockCookies,
}: {
  language: string;
  blockCookies?: boolean;
}): YoutubePlayerOptionsType => {
  return {
    playerVars: {
      autoplay: 0,
      loop: 1,
      rel: 0,
      showinfo: 0,
      fs: 1,
      modestbranding: 1,
      // enablejsapi: 1,
      hl: language,
    },
    host: blockCookies ? "https://www.youtube-nocookie.com" : "https://www.youtube.com",
  };
};
