// Core
import { useRouter } from "next/router";

// Components
import { Leads } from "components/common/Leads";

// Utils
import { GET_ENUMS, GET_PARAMS } from "utils/constants";
import { useTranslation } from "client/utils/i18n/client";

export const FeedbackIntroLead = () => {
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <Leads
      testId="intro-feedback"
      title={t("intro:feedback.lead.title")}
      buttonText={t("intro:feedback.lead.btn")}
      href={{
        pathname: router.pathname,
        query: { [GET_PARAMS.popup]: GET_ENUMS.popup.callBack },
      }}
    />
  );
};
