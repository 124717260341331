// Core
import cx from "classnames";

// Components
import { Row, Col, Grid } from "antd";
import { Title } from "components/ui/Typography/Title";
import { Text } from "components/ui/Typography/Text";
import { Container } from "components/common/Container";
import { Image } from "components/ui/Image";

// Hooks
import { useUserCountry } from "bus/user/hooks/useUserCountry";
import { useTranslation } from "client/utils/i18n/client";

// Utils
import { rowGutters } from "utils/constants";
import { introHero } from "data/intro";
import { UserCountryCodesEnum } from "bus/user/models";

import st from "./styles.module.css";

type IntroSigninSignupProps = {
  form: JSX.Element;
};

export const IntroHero = (props: IntroSigninSignupProps) => {
  const { form } = props;

  const { t } = useTranslation();
  const { userCountry } = useUserCountry();

  const { md = true, lg = true } = Grid.useBreakpoint();
  const isTablet = !lg;
  const isMobile = !md;

  const heroStyles = cx(st.hero, {
    [st.mobile]: isMobile,
    [st.tablet]: isTablet,
  });
  const heroFormStyles = cx(st.form, {
    [st.mobile]: isMobile,
    [st.tablet]: isTablet,
  });

  const brandStyles = cx(st.brand, {
    [st.mobile]: isMobile,
    [st.tablet]: isTablet,
  });

  const bgImgJSX = t(introHero.img.alt);

  const getlogoSrc = (language?: UserCountryCodesEnum): string => {
    const src = language && introHero.logo[language];
    return src || introHero.logo[UserCountryCodesEnum.en];
  };

  const logoSrc = getlogoSrc(userCountry);

  return (
    <section className={heroStyles} data-test-id="signin-hero">
      <Image
        src={introHero.img.src}
        alt={bgImgJSX}
        layout="fill"
        objectFit="scale-down"
        objectPosition="right top"
        placeholder="blur"
        priority
        unoptimized
      />
      <Container>
        <div className={st.content}>
          <div className={heroFormStyles}>
            {!isTablet && !isMobile && (
              <Row gutter={rowGutters} align="middle" justify="space-between">
                <Col lg={12}>
                  <Title tag="h1" size="44" color="white-100" font="second" fontWeight="700">
                    {t(introHero.title)}
                  </Title>
                  <ul className={st.desc}>
                    {introHero.desc.map((item) => (
                      <li key={item}>
                        <Text size="20" color="main-gray-3" fontWeight="400">
                          {t(item)}
                        </Text>
                      </li>
                    ))}
                  </ul>
                </Col>
                <Col xl={8} lg={12}>
                  {form}
                </Col>
              </Row>
            )}

            {isTablet && !isMobile && (
              <Row gutter={rowGutters} align="middle" justify="space-between">
                <Col md={12}>
                  <Row gutter={rowGutters}>
                    <Col md={24}>
                      <Title tag="h1" size="36" color="white-100" font="second" fontWeight="700">
                        {t(introHero.title)}
                      </Title>
                      <ul className={st["desc-tablet"]}>
                        {introHero.desc.map((item) => (
                          <li key={item}>
                            <Text size="20" color="main-gray-3" fontWeight="300">
                              {t(item)}
                            </Text>
                          </li>
                        ))}
                      </ul>
                    </Col>
                  </Row>
                </Col>
                <Col md={12}>{form}</Col>
              </Row>
            )}

            {isMobile && (
              <Row gutter={rowGutters}>
                <Col span={24}>
                  <div className={st["meta-mobile"]}>
                    <Title
                      tag="h1"
                      size="24"
                      color="white-100"
                      font="second"
                      fontWeight="700"
                      alignmentHorizontal="center"
                    >
                      {t(introHero.title)}
                    </Title>
                    <ul className={st["desc-mobile"]}>
                      {introHero.desc.map((item) => (
                        <li key={item} className={st["desc-item"]}>
                          <Text size="16" color="main-gray-3" fontWeight="400">
                            {t(item)}
                          </Text>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Col>
                <Col span={24}>{form}</Col>
              </Row>
            )}
          </div>

          <div className={brandStyles}>
            <Row gutter={rowGutters} justify="center">
              <Col lg={12} md={18} xs={18}>
                <Image
                  src={logoSrc}
                  key={logoSrc}
                  width={632}
                  height={80}
                  alt={t("common:firstPartPageTitle")}
                  unoptimized
                  priority
                />
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </section>
  );
};
