// Core
import { memo, useCallback, useMemo, useState } from "react";
import cx from "classnames";

// Definitions
import type {
  YoutubePlayerOptionsType,
  YoutubePlayerPropsType,
} from "components/ui/YoutubePlayer/YoutubePlayer";
import type { ConsentCookiesType } from "client/core/consent-cookies/models";

// Hooks
import { useUserCountry } from "bus/user/hooks/useUserCountry";
import { useCookies } from "client/hooks/use-cookies";
import { useTranslation } from "client/utils/i18n/client";

// Components
import { Grid } from "antd";
import { Image } from "components/ui/Image";
import { DynamicImport } from "components/common/DynamicImport/DynamicImport";

// Utils
import { introRetailer } from "data/intro";
import { CONSENT_COOKIE_KEY } from "client/core/consent-cookies/constants";
import { getRetailerVideoOps, getVideoId } from "./utils";
import st from "./Styles.module.less";

const YoutubePlayerDynamic = memo(
  DynamicImport<YoutubePlayerPropsType>(import("components/ui/YoutubePlayer"), "YoutubePlayer"),
);

export const RetailerVideo = () => {
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const { md = true, lg = true } = Grid.useBreakpoint();
  const { userCountry } = useUserCountry();

  const cookies = useCookies<
    typeof CONSENT_COOKIE_KEY,
    { [CONSENT_COOKIE_KEY]: ConsentCookiesType }
  >([CONSENT_COOKIE_KEY]);
  const consentCookiesStatistics = cookies[CONSENT_COOKIE_KEY]?.statistics;

  const videoId = getVideoId(userCountry);
  const videoAltJSX = t(introRetailer.video.alt);
  const videoSrc = introRetailer.video.src;
  const videoStyles = cx(st["retailer-video"], { [st.mobile]: !md, [st.tablet]: !lg });

  const shouldBlockCookies = !(
    consentCookiesStatistics?.isEnabled &&
    consentCookiesStatistics?.ysc &&
    consentCookiesStatistics?.visitorInfoLive
  );

  const videoOpts: YoutubePlayerOptionsType = useMemo(
    () =>
      getRetailerVideoOps({
        language: i18n.language,
        blockCookies: shouldBlockCookies,
      }),
    [i18n.language, shouldBlockCookies],
  );

  const handleReady = useCallback(() => setLoading(false), []);

  return (
    <div className={videoStyles}>
      {loading && (
        <div className={st["retailer-video-preview"]}>
          <Image src={videoSrc} alt={videoAltJSX} width={1064} height={592} placeholder="blur" />
        </div>
      )}
      <YoutubePlayerDynamic videoId={videoId} options={videoOpts} onReady={handleReady} />
    </div>
  );
};
