// Core
import { useCookies as useReactCookies } from "react-cookie";

export const useCookies = <
  Key extends string,
  Output = {
    [K in Key]?: string | number | boolean | Record<string, unknown>;
  },
>(
  dependencies?: Key[],
  options?: { doNotParse?: boolean; doNotUpdate?: boolean },
): Output => {
  const [cookies] = useReactCookies(dependencies, options);
  return cookies as Output;
};
